export const ALLOW_ATTRIBUTES = [
  'registeredUserType',
  'userType',
  'email',
  'subdomain',
  'isCARAccountLinked',
  'userMailingAddressState',
]
export const ALLOW_PARTIAL_TRACK = []
export const CAR_PARTNER = 'car'
export const RENT_PAYMENT_LANDLORD_SURVEY = 'rent_payment_landlord_survey'
export const RCM_1_IMPROVEMENT_2 = 'rcm_1_improvement_2'
export const RCM_1_7 = 'rcm_1_7'
export const RCM_1_7_EXPERIMENT = 'rcm_1_7_experiment'

export const RENT_PAYMENT_FEATURE_FLAG_KEY = {
  UPDATE_RECEIVING_ACCOUNT: 'rent_payment_update_receiving_account',
  LANDLORD_ABSORB_FEE: 'rent_payment_landlord_absorb_fee',
  MONTH_TO_MONTH: 'rent_payment_month_to_month',
}

export const OVERHAUL_RENT_PAYMENT = `overhaul_rent_payment`

export const LANDLORD_ABSORB_FEE_EXPERIMENT = 'rent_payment_landlord_absorbs_fee_experiment'
export const LANDLORD_ABSORB_FEE_EXPERIMENT_OPTION = 'is_separated'

export const LANDLORD_OPTIMIZATION_EXPERIMENT = 'landlord_optimization_flow_experiment'
export const IS_OPTIMIZED = 'is_optimized'

export const PRE_SCREEN_QUESTION = 'pre_screen_question'

export const RENTSPREE_PRO_BANNER_EXPERIMENT = 'rentspree_pro_banner_experiment'

export const ESIGN_SAVE_TEMPLATE = 'esign_save_template'

export const PREMIUM_BUNDLE_MULTIVARIATE_DESIGN_VALUES = {
  CURRENT: 'current',
  BUNDLE_NAME_ADJUSTMENT: 'bundle_name_adjustment',
  RE_ORDERING_BUNDLE: 're-ordering_bundle',
}

export const PREMIUM_SCREENING_GATE_WITH_FIRST_TIME_USER =
  'premium_screening_gate_with_first_time_user'

export const PREMIUM_BUNDLE_DESIGN_CONCEPT_EXPERIMENT = 'premium_bundle_design_concept'

export const PREMIUM_BUNDLE_DESIGN_CONCEPT_PARAMS = 'design'

export const PREMIUM_BUNDLE_DESIGN_CONCEPT_VALUES = {
  CURRENT: 'current',
  INCOME_VERIFICATION: 'income_verification',
  REMOVE_BUNDLE: 'remove_bundle',
}

export const SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT = {
  VALUE: 'skip_tu_agreement_and_address_experiment',
  KEYS: {
    IS_SKIP: 'isSkip',
  },
}

export const NEW_UI_TABLE_SCREENING = 'new_ui_table_screening'
export const NEW_UI_TABLE_PROPERTY_APPLICATION = 'new_ui_table_property_application'
export const NEW_UI_TABLE_PROPERTY = 'new_ui_table_property'
export const NEW_UI_TABLE_LEASE_L2 = 'new_ui_table_lease_l2'

export const INCOME_VERIFICATION_SHARE_REPORT = 'income_verification_share_report'
export const CUSTOM_DOCUMENT = 'custom_document'

export const NEW_DASHBOARD_FOR_EXISTING_USER_WITH_AT_LEAST_ONE_SCREENING =
  'new_dashboard_for_existing_user_with_at_least_one_screening'

export const LEASE_AGREEMENT_REDESIGN_SELECT_TEMPLATE_GATE =
  'lease_agreement_redesign_select_template'

export const NEW_LISTING_MANAGEMENT_SECTION_ON_PROPERTY_OVERVIEW =
  'new_listing_management_section_on_property_overview'

export const DEPRECATE_LISTED_BY_FIELD = 'deprecate_listed_by_field'
export const NEW_POPPER_DISCLAIMER_FOR_LISTING_SYNDICATION =
  'new_popper_disclaimer_for_listing_syndication'

export const PROPERTY_ADDRESS_FORM_REDESIGN = 'property_address_form_redesign'

export const ONBOARDING_PARTNER_AGENT = 'onboarding_partner_agent'
export const ONBOARDING_PARTNER_AGENT_EXPERIMENT = 'onboarding_partner_agent_experiment'

export const UPDATE_MS_SHARE_REQUEST = 'update_ms_share_request'

export const REALTOR_LISTING_SYNDICATION_LOGO = 'realtor_listing_syndication_logo'

export const LANDLORD_GETS_ADVANCE_PAYMENT_LANDLORD_DASHBOARD =
  'landlord_gets_advance_payment_landlord_dashboard'
