import { Box } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React, { useState, useEffect } from 'react'

// import { AddLateFeeComponent } from 'v3/containers/overhaul-rent-payment/components/payment-forms/late-fee'
import { TellUsAboutPayment } from 'v3/containers/overhaul-rent-payment/components/payment-forms/tell-us-about-payment'
import { PaymentCategories } from 'v3/containers/overhaul-rent-payment/constants'
import {
  findPaymentCategory,
  generateAPIQuotation,
  updatePayment,
} from 'v3/containers/overhaul-rent-payment/pages/utils'
import { ABOUT_PAYMENT } from 'v3/containers/overhaul-rent-payment/text-constants'

export const RentPaymentComponent = ({
  payments,
  setPayments,
  _pageIndexes,
  _setDrawerOpen,
  _selectionOptions,
  _paymentSelections,
  _setPaymentSelections,
  setIsValid,
}) => {
  // we can only have one rent payment, so either grab it or template a fresh one
  const [, rentPayment] = findPaymentCategory(payments, PaymentCategories.RENT)

  const [localPayment, setLocalPayment] = useState({
    ...rentPayment,
    amount: rentPayment?.amount,
    startDate: rentPayment?.type === 'oneTime' ? rentPayment?.dueDate : rentPayment?.startDate,
  })

  // save a version of the rent payment as it's validated
  const localPaymentJSON = JSON.stringify(localPayment)
  useEffect(() => {
    const quotation = generateAPIQuotation(localPayment)
    updatePayment({ nextPayment: quotation, payments, setPayments })
  }, [localPaymentJSON])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        textAlign: 'left',
      }}
    >
      <Typography sx={{ marginBottom: '15px' }} variant="title-medium">
        {ABOUT_PAYMENT.RENT_TITLE}
      </Typography>
      <TellUsAboutPayment
        payment={localPayment}
        setPayment={setLocalPayment}
        setIsValid={setIsValid}
      />
      {/* <AddLateFeeComponent
        rentPayment={rentPayment}
        payments={payments}
        setPayments={setPayments}
        setDrawerOpen={_setDrawerOpen}
      /> */}
    </Box>
  )
}
